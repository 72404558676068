<template>
	<div class="metas py-5">
		<div class="container">
			<div class="d-flex align-items-center justify-content-center" style="height:250px;" v-if="load">
				<loader color="#e0a638" size="100" />
			</div>
			<div class="col-md-12">
				<transition name="fade">
					<div v-show="!load">
					<ButtonToggle @toggleConvert='toggleBtn' :activo='activoCurrent' v-if="$store.getters.getUser.profile == 2" />
					<p class="my-3 titulo_dash" >Suas metas para este mês</p>
					</div>
				</transition>

				<div class="col-md-12">
					<div class="row">
						<div class="col-md-2">
							<div class="row">
								<transition name="vertical">
									<totalMetas
										:totalMetas="totalMetas"
										class="col-md-12"
										v-show="!load"
										:ticketAverage.sync="ticketAverage"
										:ticketAverageLastMonth.sync="ticketAverageLastMonth"
										:porceto='activoCurrent'
									/>
								</transition>
							</div>
						</div>
						<div class="col-md-10">
							<transition-group name="list-complete" tag="div" class="row">
								<metrica
									v-for="(item , index) in categoryScore"
									:key="index +1"
									:item="item"
									class="col-4 mb-2 list-complete-item"
									:porceto='activoCurrent'
								/>
							</transition-group>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import totalMetas from "@/components/metas/vendedor/_Total_metas";
import metrica from "@/components/metas/vendedor/Metas_mensais";
import loader from "@/components/utils/_Load";
import ButtonToggle from '../components/metas/buttons/button_toggle.vue';
import HTTP from "@/api_system";
	export default {
		components: {
			totalMetas,
			metrica,
			loader,
			ButtonToggle,
		},
		data() {
			return {
				servicesScore: [],
				load: true,
				ticketAverage: "",
				ticketAverageLastMonth: "",
				categoryScore: [],
				activoCurrent: 'Percent',
				categoryScoreTrue: false
			};
		},
		mounted() {
			if (this.$store.getters.getUser.profile == 2) {
				this.getFranshiseGoal();
			} else {
				// this.saleGoal();

				this.getSalesman()

				return
				this.$store
					.dispatch("GetlistScorebycategory")
					.then((resolve) => {
						this.load = false;
						this.categoryScore = resolve;
						this.categoryScoreTrue = true
					})
					.catch((error) => {
						this.$notify({
							group: "erros",
							type: "error",
							text: `<i class="icon ion-close-circled"></i>Ops algo deu errado teste`,
						});
					});
			}
		},
		methods: {
			getSalesman() {

				HTTP.get("services/app/Score/GetScoreByServiceBySalesman", {
					headers: {
						Authorization: `Bearer ${this.$store.getters.getterToken}`,
					},
				})
				.then(( {data} ) => {
						this.load = false;
						this.ticketAverage = data.result.ticketAverage;
						this.ticketAverageLastMonth = data.result.ticketAverageLastMonth;
						this.categoryScore = data.result.listScoreByCategory;
				}).catch((error) => {
						this.$notify({
							group: "erros",
							type: "error",
							text: `<i class="icon ion-close-circled"></i>Ops algo deu errado`,
						});
					});


			},getFranshiseGoal() {
				this.load = true;
				HTTP.get("services/app/Score/GetScoreByServiceByFranchise", {
					headers: {
						Authorization: `Bearer ${this.$store.getters.getterToken}`,
					},
				})
					.then((response) => {
						this.load = false;
						this.ticketAverage = response.data.result.ticketAverage;
						this.ticketAverageLastMonth =
							response.data.result.ticketAverageLastMonth;
						this.categoryScore = response.data.result.listScoreByCategory;
					})
					.catch((error) => {
						this.$notify({
							group: "erros",
							type: "error",
							text: `<i class="icon ion-close-circled"></i>Ops algo deu errado`,
						});
					});
			},
			saleGoal() {
				this.load = true;
				this.$store
					.dispatch("GetListscoreByService")
					.then((resolve) => {
						this.ticketAverage = resolve.ticketAverage;
						this.categoryScore = resolve.listScoreByCategory;
						this.ticketAverageLastMonth = resolve.ticketAverageLastMonth;
						this.servicesScore = resolve.listScoreByService;
						this.load = false;
					})
					.catch((reject) => {
						this.$notify({
							group: "erros",
							type: "error",
							text: `<i class="icon ion-close-circled"></i>Ops algo deu errado`,
						});
					});
			},
			toggleBtn(e) {
				this.activoCurrent = e
			}
		},
		computed: {
			totalMetas() {
				return this.$store.getters.totalMetas;
			},
		},
	};
</script>

<style scoped>
.metas {
	background-color: #357eb8;
}
.list-complete-item {
	transition: all 1s !important;
}
.vertical-enter-active {
	transition: all 1s !important;
}
.teste {
	background: rgb(80, 38, 38);
	margin: 10px;
}
</style>
